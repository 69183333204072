@font-face {
    font-family: 'icomoon';
    src:url('icons/icomoon/fonts/icomoon.eot?3p0rtw');
    src:url('icons/icomoon/fonts/icomoon.eot?#iefix3p0rtw') format('embedded-opentype'),
    url('icons/icomoon/fonts/icomoon.woff?3p0rtw') format('woff'),
    url('icons/icomoon/fonts/icomoon.ttf?3p0rtw') format('truetype'),
    url('icons/icomoon/fonts/icomoon.svg?3p0rtw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../css/icons/glyphicons/glyphicons-halflings-regular.eot');
    src: url('../css/icons/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../css/icons/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'), url('../css/icons/glyphicons/glyphicons-halflings-regular.woff') format('woff'), url('../css/icons/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'), url('../css/icons/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

@font-face{
    font-family:"summernote";
    font-style:normal;
    font-weight:normal;
    src:url("../css/icons/summernote/summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
    src:url("../css/icons/summernote/summernote.eot?#iefix") format("embedded-opentype"),
    url("../css/icons/summernote/summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"),
    url("../css/icons/summernote/summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype")
}
.icon-popular-app {
    background-repeat:no-repeat!important;
    background-position:1000px 1000px!important;
}

.navigation li a:hover .icon-popular-app:before, .navigation li a:focus .icon-popular-app:before{
    filter:invert(1);
}

.icon-popular-app:before{
    content: '';
    width: 16px;
    height: 16px;
    padding-left: 16px;
    background-image: inherit;
    background-position:0px 0px;
    filter:invert(0.8);
}

.icon-popular-app-invert:before{
    content: '';
    width: 16px;
    height: 16px;
    padding-left: 16px;
    background-image: inherit;
    background-position:0px 0px;
}

img {max-width: 100%;}
.border-color-light-grey {border-color:#ddd;}

.icon-mailchimp{ background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhklEQVQ4T43Tv0uXURQG8I/0E4qIilwsaklwkH4QOWiWFApK0dIi4tAUUVBNFYWE/RoDEaJEWyJBJ3FIB6mhfyCHBhFCCpr6RZBIVCfOCy/yfsG7vNz3nvOc53nuc+usfW3Gr9XldRX9G3AJ2/Epzy/iIIZwudyzGmAbBvAYe7Eb99CYTe/QXAtgIwZxF79xATcxgfVoxSyu1wK4haf4nizO4y1OJ5PoO4OpKoBjaMAk7qMPf7AHH/ADD9CCK1UAQftOTnuVBeFPmDmc+50pK1h+KUAKE4N+mPUGbXkYDPajHh/xEyfQgUf5TwHwEItowpE0LHDO4nWaGDn4jPg+QW8UFAA70t351PsSWzCNnpLmdbnvxA18KwAiPNcS+TBGsA9dmMNxrGAXZnAIB/CsHKTuDExMPJlTr+JFTlvAVyxlKsdDUlWUx9CfAKE5TIur3JoenEI7bpc9KF9tyBktTEq3Q1JkIaREKs9huRZAARZS4hEdxSa8x/P0KaL+f1VJWPsD/1f5F8mOSRGZPevmAAAAAElFTkSuQmCC); }
.icon-xero{ background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABMklEQVQ4T63TzyumYRTG8c+rBpnsZ4OdUjJloSQbZWqslGQjpSgmlPwZU/QuRJnUJCUbG1I0MxaTkpUfsR+L2SgpK0o6dT968uONeHbPue/re59zrnMKHn+NGEYnatPxP2xjAcd5SSH3U44ZjKDsCXCEbjGPSVxHIAOEeBMdzwgfhn/ja0AywCy+vVCcXQvNWACi5oMSaT/HjXI+B6CIiVe+nl0vBuAEFdhHH36iHhuI3gxiCQ3JgQs0ox+nAbjCR6xgB1+SOGz8gD+oRjtu8AnrGEdVHrCII7RgFwMpg3P8RSsuUybLmEJlvoRDdGMVdVhLNo9iD734jv9oQ09Wwpub+BYbm95lkMLT147yL3TlRzmDTCOaVmqZ5tIyhaX3y5QfxOjJUJqHmnRwhi38eLjOdxHHQBoN7QabAAAAAElFTkSuQmCC); }
.navbar-top-settings>.dropdown-user>a {padding-top:12px;padding-bottom:12px;}


#root {display: table;table-layout: fixed; height:100% }
@media(max-width: 767px) {
    #root {width:100%;}
    .sidebar-mobile-main .sidebar-main, .sidebar-mobile-secondary .sidebar-secondary, .sidebar-mobile-opposite .sidebar-opposite, .sidebar-mobile-detached .sidebar-detached>.sidebar {
        display: block;
    }
    .sidebar-mobile-opposite-toggle {display: none!important;}
        .sidebar-opposite-visible .sidebar-mobile-opposite-toggle {display: block!important;}
}
    #root > div:not(.navbar) {height:100%}
    #root > .sidebar-opposite-visible > div:not(.navbar) {height:100%}

.full-width {width:100%}
.full-height {height:100%;top:0px;bottom:0px;}
.position-absolute {position: absolute;}
.fill-available-panel {height: calc(100% - 20px);}
.my-selection-placeholder {height:397px;}

.zend_form ul.errors {display:none;}

.logo-drop-down-1, .logo-drop-down-2, .logo-drop-down-3, .logo-drop-down-4, .logo-drop-down-5, .logo-drop-down-6 {text-align: center;}

@media (min-width:1199px){
    .logo-drop-down-1, .logo-drop-down-4 {text-align: right;}
    .logo-drop-down-2, .logo-drop-down-5 {text-align: center;}
    .logo-drop-down-3, .logo-drop-down-6 {text-align: left;}
}

.logo-drop-down-1 label,
.logo-drop-down-2 label,
.logo-drop-down-3 label,
.logo-drop-down-4 label,
.logo-drop-down-5 label,
.logo-drop-down-6 label {
    display: none;
}

.logo-drop-down-1 i,
.logo-drop-down-2 i,
.logo-drop-down-3 i,
.logo-drop-down-4 i,
.logo-drop-down-5 i,
.logo-drop-down-6 i {
    margin-right:10px;
    padding: 9px;
    border: 1px dashed #ccc;
}


.ad-preview {
    border: 3px solid #1268a1;
    padding: 20px 5px 0px 5px;
    margin: auto;
    position: relative;
    width: 460px;
}

.ad_area {padding: 0px 20px 20px;}

.preview-overlay {
    background: #fff none repeat scroll 0 0;
    margin-top: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom:0px;
    left:0px;
    right: 0px;
}

.top-logo-area img, .bottom-logo-area img {max-width:440px;}

.job_ad_template p, .ad-preview p {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    line-height: 1.3em;
}

.ad-preview p, .ad-preview ul {
    color: #000;
    margin-bottom: 12px;
    margin-top: 12px;
}

.template-preview .no_template {
    border: 3px solid #1268a1;
    padding: 20px;
    margin: auto;
    position: relative;
    width: 460px;
}

.table-responsive .label {min-width:50px;}

.calendar-heading {
    max-width: 45px; text-align: center;width:45px;padding-left:0px!important;padding-right:0px!important;overflow:hidden;-ms-text-overflow: ellipsis;text-overflow: ellipsis;
}

.calendar-day {
    max-width: 45px; text-align: center;width:45px;padding-left:5px!important;padding-right:5px!important;overflow:hidden;-ms-text-overflow: ellipsis;text-overflow: ellipsis; font-size: 0.9em;
}

.retreat-calendar td {
    min-width:43px!important;
}

.retreat-calendar th {
    min-width:43px!important;
}

.view-order:hover {
    color:#fff;
}

.calendar-day.cell0 {background-color:#f6b26b;}
.calendar-day.cell1 {background-color:#00cc99;}
.calendar-day.cell2 {background-color:#ffd966;}
.calendar-day.cell3 {background-color:#ff6699;}
.calendar-day.cell4 {background-color:#b4a7d6;}

.page-loader-internal {
    text-align:center;padding-bottom:40px;
}

.page-loader-internal i {
    font-size: 80px;
}

.diary_link {text-decoration: underline dotted;}
.cancelled-order td {color:#999;}
.spreadsheets .table-responsive {max-height:none!important;}
.btn-multi-line {white-space: normal!important;}
.no-table-header .datatable-header {display:none!important;}
.no-table-footer .datatable-footer {display:none!important;}
.no-footer .datatable-footer {/*display:none;*/}

form .select2-container {
    width: 100% !important;
}

.horizontal-inline-form {max-height: none;}
.horizontal-inline-form > div {border-top: 1px solid #ddd;}
.editable-textarea {width: 320px!important;height:200px!important;}
.bg-highlight {background: #f8f8f8;padding:12px 20px;}

.editable-address select{min-width:180px!important;}
.badge-icon {position:absolute;top:-10px; left:10px;}

.multiselect-filtering ~ .btn-group {width:100%;}
.datatable-header a.btn {margin-left:5px!important;}

.swal2-title {color:#000!important;font-size:19px!important;font-weight:normal!important;}
.swal2-content {color:#000!important;font-weight:normal!important;font-size:13px!important;text-align:center!important;}
.swal2-footer {text-align:center!important;}
.swal2-actions button {padding: 7px 15px!important;font-size:13px!important;font-weight:normal!important;}
.swal2-icon-success .swal2-actions button {background-color:#7CB342!important;box-shadow:none!important;}
.swal2-icon-warning .swal2-actions button {background-color:#F4511E!important;box-shadow:none!important;}
.swal2-icon-error .swal2-actions button {background-color:#E53935!important;box-shadow:none!important;}

.signup_login_combined .content {padding:0px;}
.signup_login_combined .panel-body {padding:0px; border:none;}
.signup_login_combined .panel {box-shadow:none;}
.signup_login_combined .content-wrapper {vertical-align:top;}
.signup_login_combined .logo-block,
.signup_login_combined .footer {display:none;}
.signup-logo {max-width: 250px;}

.modal-footer {position:relative;}

@media(min-width: 1025px){
    .desktop-flex {
        display: flex;
        align-content: flex-start;
    }
    .desktop-flex > div > div {
        height:100%;
    }
}

.btn-hide-collapsed.panel-collapsed .heading-elements button {display: none;}

.fc table {box-sizing: border-box;}
.fc-day-grid-event > .fc-content {white-space: normal!important;}
.company-dashboard-logo-wrapper .media-left {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    padding: 0px 20px;
    height: 40px;
    width: 40px;
}
.company-dashboard-logo-wrapper * {
    vertical-align: middle;
    color:#fff;
}
.company-dashboard-logo-wrapper a {
    display: table-row;
}
.company-dashboard-logo-wrapper .media-left:not(.hide) + .media-body {
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-md-display-flex {display: flex;}
    .col-md-vertical-center {align-items: center;}
    .col-md-vertical-bottom {align-items: end;}
    .col-md-horizontal-center {justify-content: center;}
}

.font-30 {font-size:30px!important;}
.content.summernote {padding: 0px;}

@media (min-width: 769px) {
    .sidebar-xs .sidebar-main .sidebar-user .media-left,
    .sidebar-xs .sidebar-main .sidebar-user .media-right {
        margin-left: auto;
        margin-right: auto;
    }
}
.img-profile-photo {max-width: 140px;}
.img-driver-photo {max-width: 140px;}
.file-thumb-loading:before, .file-thumb-loading:after {display: none;}
.img-xlg {width:50px!important;height:50px!important;}
.text-size-super-mini {font-size: 7px!important;}
.text-size-xmini {font-size: 11px!important;}
.no-max-height {max-height: none;}
.alert-disabled {background:#e8e8e8;cursor:not-allowed;}
.inline-checkbox input {height: 20px; width: 20px;}
.other-sponsors-carousel div, .other-sponsors-carousel a {max-height:60px;height:60px;text-align:center;display:block;}
    .other-sponsors-carousel img {max-height:60px;margin-left:auto;margin-right:auto;}

.other-prizes {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    align-items: center;
}

.other-prizes > div {
    height:100%;
    position: relative;
}

.other-prizes > div > .thumbnail {
    height: 100%;
}

@media (min-width: 992px) {
    .other-prizes {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .other-prizes {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
@media (max-width: 767px) {
    .other-prizes {
        grid-template-columns: 1fr 1fr;
    }
}

.player-style .datatable-xtreme th {font-size:15px;font-weight:700;}
.player-style .datatable-xtreme td, .player-style .datatable-xtreme a {font-weight:700; color:#000;}
.player-style .dataTables_info, .player-style .dataTables_length, .player-style .panel-heading .icons-list {display: none;}

.border-dashed {border-style: dashed;}

.one-line-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: 769px) {
    .sidebar-xs .sidebar-main .sponsors {
        display: none;
    }
}

.navbar-inverse {
    background-color: #212731;
    border-color: #212731;
}
body {background-color: #3fa1a5;}
.bg-cfibre {background:url("../images/backgrounds/cfibre.jpg") no-repeat center center; background-size:cover;}
.bg-prize-series {background:url("../images/backgrounds/prize_series.jpg") no-repeat left top #dd2929;}
.bg-prize-round {background:url("../images/backgrounds/prize_round.jpg") no-repeat left top #020653;}
.bg-transparent {background-color: transparent!important;border-color: transparent!important;}